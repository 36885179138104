export const a = 1;

export const questionMap = {
  1: {
    headline: "彩票公益金",
    subtitle: "第一站",
    list: [
      {
        issue: "体彩全国统一发行30年以来，累计筹集公益金已超过8000亿元，这些公益金用在哪儿了？",
        options: ["A.助力体育强国建设", "B.助力国家经济发展"],
        solution: "A.助力体育强国建设",
        detail:
          "多年来，体彩公益金为支持群众体育和竞技体育事业发挥了积极作用，为健康中国和体育强国建设作出了突出贡献。同时，体育彩票通过筹集公益金用于补充全国社保基金，特别是在抗震救灾、医疗救助、教育助学、残疾人事业、养老服务等方面发挥了重要作用。"
      },
      {
        issue: "公益金的使用有人监督吗？",
        options: ["A.有", "B.没有"],
        solution: "A.有",
        detail: "体育彩票公益金，你我都是受益人，更是监督人，体育彩票公益金的使用情况上网可查。"
      },
      {
        issue: "不买彩票能否享受公益金的福利？",
        options: ["A.能", "B.不能"],
        solution: "A.能",
        detail:
          "“来之于民、用之于民”是中国体育彩票的发行宗旨，体彩公益金在体育事业和社会公益事业的广泛应用，也使中国体育彩票被誉为“体育事业的生命线”和“公益事业的助推器”。"
      },
      {
        issue: "我不是体育爱好者，体彩公益金和我有关吗？",
        options: ["A.有", "B.没有"],
        solution: "A.有",
        detail:
          "民生是人民幸福之基、社会和谐之本。中国体育彩票紧跟国家发展步伐，筹集的公益金不仅成为体育事业支柱型的资金来源，更被广泛应用于民生各领域。近年来，彩票公益金的使用方向已越加聚焦于完善基本公共服务，集中于满足人民对美好生活的需求。即便不是体育爱好者也能受益于彩票公益金。"
      }
    ]
  },
  2: {
    headline: "南京体彩爱心工作室",
    subtitle: "第二站",
    list: [
      {
        issue: "截止到现在，南京体彩已经举办了多少届母亲节主题文化活动？",
        options: ["A.十一届", "B.十二届"],
        solution: "B.十二届",
        detail:
          "2013年起，南京体彩坚持开展母亲节文化主题活动，引导中心员工们不仅要传承中华民族优良文化传统，树立正确良好的家风家教，用“德孝文化”涵养中华文明之根，也要通过增强工作中的责任感和使命感，为体彩事业的发展提供更有力的亲情保障。截止到2024年，已连续开展了十二届。"
      },
      {
        issue: "下面哪个活动是南京体彩爱心工作室创办的活动？",
        options: ["A.彩粉笔支教计划", "B.快乐操场"],
        solution: "A.彩粉笔支教计划",
        detail: "彩粉笔支教计划是南京体彩坚持为宁燕外来工子弟小学支教，并为他们送去文体用品，开展音乐、科学、品德等课程，让孩子们开阔了眼界，学到了更多知识。"
      },
      {
        issue: "南京体彩公益文化日”具体指的是哪一天？",
        options: ["A.5月20日", "B.5月21日"],
        solution: "A.5月20日",
        detail:
          // eslint-disable-next-line max-len
          "“5·20”因为谐音而被网友们誉为有爱的日子，从2016年起，南京体彩将每年的5月20日命名为“南京体彩公益文化日”，并将连续开展一个月的公益文化活动。这些年以来，南京体彩成功举办了“爱心捐赠·共沐书香”、“书香体彩·悦读人生”、“大爱人间·读行天下”、“乐小星x大红帽——乐享阅读健身新风尚”等活动，用公益表达爱。"
      },
      {
        issue: "2023年12月，南京体彩与环宇城幸福蓝海影城强强联手，共同打造的公益厅正式亮相。请问该公益厅的具体名称是什么？",
        options: ["A.南京体彩微光公益厅", "B.乐小星主题公益厅"],
        solution: "A.南京体彩微光公益厅",
        detail:
          "2023年12月，南京体彩与环宇城幸福蓝海影城强强联手，共同打造的“南京体彩微光公益厅”正式亮相，将体彩公益与休闲娱乐紧密融合，让品牌更具年轻化，吸引更多年轻群体关注体彩。"
      },
      {
        issue:
          "中国体育彩票是国家公益彩票，作为体彩大家庭中的一份子，南京体彩在公益之路上坚定踏实，从“三爱”品牌发展到“体彩爱心工作室”。请问“三爱”品牌具体指的是什么？",
        options: ["A.爱心大巴、爱心妈妈、爱心家园", "B.爱心妈妈、爱心家园、爱心器材"],
        solution: "A.爱心大巴、爱心妈妈、爱心家园",
        detail:
          // eslint-disable-next-line max-len
          "中国体育彩票是国家公益彩票，作为体彩大家庭中的一份子，南京体彩在公益之路上坚定踏实，从“三爱”品牌(爱心妈妈、爱心家园、爱心大巴)发展到“体彩爱心工作室”，南京体彩通过各项活动的开展，一点一滴的将体彩公益理念传播到各个人群之间，在社会上形成了互帮互助、乐善好施的公益风气，真正履行了国家公益彩票的社会责任。"
      }
    ]
  },
  3: {
    headline: "责任彩票知道多少",
    subtitle: "第三站",
    list: [
      {
        issue: "哪项行为可能表明购彩者存在沉迷风险?",
        options: ["A.频繁购买彩票，对日常生活和工作已造成伤害", "B.偶尔购买彩票作为娱乐"],
        solution: "A.频繁购买彩票，对日常生活和工作已造成伤害",
        detail: "广大购彩者需理性购彩、量力而行，将购买体育彩票当做一种娱乐方式，在放松身心的同时，为公益事业贡献自己的一份力量。"
      },
      {
        issue: "个人单次购买竞彩或高频购买金额超过1万元需要填写大额投注承诺书",
        options: ["A.正确", "B.错误"],
        solution: "A.正确",
        detail: "2020年10月19日,财政部和体育总局联合颁布《关于进一步调整单场竞猜游戏规则有关事宜的通知》，要求对大额投注购彩者进行预约和实名登记。"
      },
      {
        issue: "在中华人民共和国境内，擅自发行、销售的境外彩票属于非法彩票。",
        options: ["A.正确", "B.错误"],
        solution: "A.正确",
        detail:
          "《彩票管理条例实施细则》规定，非法彩票包括：一、未经国务院特许，擅自发行、销售的彩票之外的其他彩票；二、在中华人民共和国境内，擅自发行、销售的境外彩票；三、未经财政部批准，擅自发行、销售的彩票；四、未经彩票发行机构、彩票销售机构委托，擅自销售的彩票；五、擅自利用互联网销售的彩票。"
      }
    ]
  }
};
